import { useGetNordicCertificateForId } from '@apis';
import { PageLayout } from '@components/PageLayouts';
import Table from '@components/Table';
import { AuthenticatedUser } from '@generated/account/src';
import { Certificate } from '@generated/licenseholders/src';
import withUser from '@hoc/withUser';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import Loading from '@primitives/Loading';
import { FilePdf } from '@styled-icons/fa-solid';
import { formatTz } from '@utils/dateUtils';
import roleEnum from '@utils/roleEnum';
import sv from 'date-fns/locale/sv';
import React from 'react';
import { CellProps, Column } from 'react-table';

interface Props {
  user: AuthenticatedUser;
}

const MyNordicCertificateResults = ({
  user: { licenseId },
}: Props): JSX.Element => {
  const columns: Column<Certificate>[] = [
    {
      Header: 'Hämta PDF',
      accessor: row => row.fileId,
      Cell: ({ value }: CellProps<Certificate, number>): JSX.Element => (
        <a
          href={`/storage/nordic/${licenseId}/${value}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          Nordenintyg
          <Icon size={16} ml={2} as={FilePdf} color="blue" />
        </a>
      ),
    },
    {
      Header: 'Bana',
      accessor: row => row.track.name,
      alignment: 'left',
    },
    {
      Header: 'Datum',
      accessor: row =>
        formatTz(row.date, 'yyMMdd', {
          locale: sv,
        }),
      alignment: 'left',
    },
    {
      Header: 'Häst',
      accessor: row => row.horseName,
      alignment: 'left',
    },
  ];

  const { data, loading, status, error } =
    useGetNordicCertificateForId(licenseId);

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }
  if (loading || !data) {
    return <Loading />;
  }

  return <Table mt={2} columns={columns} data={data} />;
};

const NordicCertificate = ({ user }: Props): JSX.Element => (
  <PageLayout headline="Nordenintyg" singleCard>
    <MyNordicCertificateResults user={user} />
  </PageLayout>
);

export default withUser(NordicCertificate, {
  roles: [roleEnum.Trainer],
  header: 'Nordenintyg',
});
