// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    CertificateTrack,
    CertificateTrackFromJSON,
    CertificateTrackToJSON,
} from './';

/**
 * 
 * @export
 * @interface Certificate
 */
export interface Certificate  {
    /**
     * the certificates file id, use this for reading the file
     * @type {number}
     * @memberof Certificate
     */
    fileId?: number;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    horseName?: string;
    /**
     * 
     * @type {CertificateTrack}
     * @memberof Certificate
     */
    track?: CertificateTrack;
    /**
     * Race date
     * @type {Date}
     * @memberof Certificate
     */
    date?: Date;
}

export function CertificateFromJSON(json: any): Certificate {
    return {
        'fileId': !exists(json, 'fileId') ? undefined : json['fileId'],
        'horseName': !exists(json, 'horseName') ? undefined : json['horseName'],
        'track': !exists(json, 'track') ? undefined : CertificateTrackFromJSON(json['track']),
        'date': !exists(json, 'date') ? undefined : parseDateTime(json['date']),
    };
}

export function CertificateToJSON(value?: Certificate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'fileId': value.fileId,
        'horseName': value.horseName,
        'track': CertificateTrackToJSON(value.track),
        'date': value.date === undefined ? undefined : value.date.toLocalDate(),
    };
}


